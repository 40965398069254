.service ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
.service li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}
.service li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.8em;
  font-weight: bold;
}
.service li li {
  margin-top: 0.9rem;
}

.service h2 {
  font-size: 1.5rem;
}

.effectiveDate {
  padding: 0.8rem 0;
}

.service article {
  margin: 1.5rem 0 0.4rem 0;
}

h2.mainHeadline {
  text-transform: uppercase;
  font-size: 2rem;
}

.subHeading {
  margin-bottom: 1rem;
}

@media screen and (max-width: 532px) {
  .service ol,
  .service p {
    font-size: 0.6rem;
  }

  .service article {
    font-size: 0.6rem;
  }

  .effectiveDate {
    margin-bottom: 0.5rem;
  }

  .service h2 {
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
  }

  .service div h2 {
    margin-top: 0;
  }

  .service li li {
    margin-top: 0.2rem;
  }

  h2.mainHeadline {
    font-size: 1.2rem;
  }
}
