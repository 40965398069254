.timeline {
  max-height: 60vh;
  overflow-y: scroll;
  font-size: 15px;
}

@media (max-width: 1366px) {
  /* Add these classes when screen is smaller than 1366px */
  .timeline {
    max-height: 50vh !important;
  }
}

.timeline ul {
  position: relative;
  margin: 0;
  padding: 0;
}

.timeline ul li {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 500px) {
  /* Add these classes when screen is bigger than 500px */
  .timeline ul li {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .timeline li::before {
    content: "";
    position: relative;
    border-radius: 50%;
    left: 134px;
    top: 3px;
    border: 8px solid #00bcd4;
  }

  .timeline .status-and-location::before {
    content: "";
    position: absolute;
    top: 16px;
    bottom: 0;
    width: 3px;
    background: #00bcd4;
    left: 141px;
  }

  .timeline .date-and-time {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-basis: 125px;

    padding-right: 24px;
    font-weight: 300;
  }

  .timeline .status-and-location {
    padding-left: 24px;
  }

  .timeline .mt-2 {
    margin-top: 2px;
  }

  .timeline .time {
    font-style: italic;
  }

  .timeline .date-and-time-small {
    display: none;
  }

  .order-details div p {
    flex-direction: row !important;
  }
}

@media (max-width: 500px) {
  /* Add these classes when screen is smaller than 500px */
  .timeline {
    font-size: 13px !important;
  }

  .order-details {
    font-size: 13px !important;
  }

  .timeline ul {
    margin-left: 10px;
  }

  .timeline ul li {
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .timeline ul li::before {
    content: "";
    position: absolute;
    top: 20px;
    bottom: 0;
    width: 3px;
    background: #00bcd4;
    left: 0;
  }

  .timeline .status-and-location::before {
    content: "";
    position: relative;
    border-radius: 50%;
    left: -27px;
    top: 17px;
    border: 8px solid #00bcd4;
  }
}

.timeline .date-and-time {
  display: none;
}

.timeline .status {
  font-weight: 500 !important;
}

.timeline .status-and-location {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  font-weight: 300;
}

.order-details {
  font-size: 15px;
  font-weight: 300;
  padding-bottom: 25px;
}

.order-details div {
  display: flex;
  margin-bottom: 5px;
}

.order-details div p {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.order-details p > span {
  margin-right: 5px;
}

.order-details p > span:first-child {
  font-weight: 500;
}
