/* Reference -> https://notiz.dev/blog/floating-form-field-with-tailwindcss */

/**
* For floating labels in form fields
*/
textarea:focus-within ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus-within ~ label,
select:not(:placeholder-shown) ~ label,
input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  /* transform */
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));

  /* scale-75  */
  --transform-scale-x: 0.75;
  --transform-scale-y: 0.75;

  /* -translate-y-6 */
  --transform-translate-y: -1.5rem;
}

textarea:focus-within ~ label,
select:focus-within ~ label,
input:focus-within ~ label {
  /* text-primary-500; */

  --text-opacity: 1;
  color: #37b0c7;
  color: rgba(55, 176, 199, var(--text-opacity));
}

input ~ label,
select ~ label,
textarea ~ label {
  /* fix for label not showing on modal */
  z-index: 0 !important;
}

/**
* Hide increment and decrement button in Chrome and Safari.
*/

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**
* Hide increment and decrement button in Firefox.
*/

input[type="number"] {
  -moz-appearance: textfield;
}
