.service h2 {
  font-size: 1.5rem;
}

h2.mainHeadline {
  text-transform: uppercase;
  font-size: 2rem;
}

.flexCenter {
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
}

.downloadBtn {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.25rem;
  font-weight: 500;
  --bg-opacity: 1;
  background-color: rgba(55, 176, 199, var(--bg-opacity));
  --text-opacity: 1;
  color: rgba(247, 250, 252, var(--text-opacity));
  border-bottom-width: 0;
}

@media screen and (max-width: 532px) {
  .service ol,
  .service p {
    font-size: 0.6rem;
  }

  .service article {
    font-size: 0.6rem;
  }

  .effectiveDate {
    margin-bottom: 0.5rem;
  }

  .service h2 {
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
  }

  .service div h2 {
    margin-top: 0;
  }

  .service li li {
    margin-top: 0.2rem;
  }

  h2.mainHeadline {
    font-size: 1.2rem;
  }
}
