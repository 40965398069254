.serviceability-details {
  font-size: 15px;
  font-weight: 300;
}

.serviceability-details p {
  display: flex;
  margin-bottom: 10px;
}

.serviceability-details p > span {
  flex: 1;
}

.serviceability-details p > span:first-child {
  font-weight: 400;
}

@media (max-width: 500px) {
  /* Add these classes when screen is smaller than 500px */
  .serviceability-details {
    font-size: 13px !important;
  }
}
