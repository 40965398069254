/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 200ms ease-in-out;
  /* transition: opacity 200ms cubic-bezier(0.25, 0.8, 0.25, 1); */
  opacity: 0;
  /* transition: transform 300ms ease-in-out; */
  /* transition-delay: 100ms; */
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  opacity: 1;
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  opacity: 0;
  /* transform: translateY(-150%); */
}

.ReactModal__Body--open {
  overflow: hidden;
}

/* For loading button */

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For smooth scroll */

html {
  scroll-behavior: smooth;
}
