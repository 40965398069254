.divspacing {
  margin-bottom: 2rem;
}

.alignleft {
  text-align: left;
}

@media screen and (max-width: 532px) {
  .divspacing {
    margin-bottom: 1rem;
  }
}
